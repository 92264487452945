import { useEffect } from "react";
import "./App.css";
import Cards from "./components/cards";
import CustomersReviews from "./components/customersReviews";
import Header from "./components/header";
import Form from "./components/form";
import Clients from "./components/clients";
import Partners from "./components/partners";
import Crytos from "./components/crytos";
import ConsultingProcess from "./components/consultingProcess";
import Blogs from "./components/blogs";
import Projects from "./components/projects";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#08071b" }}>
      <Header />
      {/* <div className="row container m-auto p-auto">
        <div className="col-5 d-flex flex-column">
          <h1 className="text-white">
            Blockchain <br /> Development Company
          </h1>
          <p className="text-white">
            Automate your business with top-tier Blockchain Development
            Services. Start today to build a better future!
          </p>
        </div>
        <div className="col-7">
          <video autoPlay muted loop playsInline className="w-100 intro_video">
            <source src="/video/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div> */}
      <div
        style={{
          position: "relative",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <video autoPlay muted loop playsInline className="w-100 intro_video">
          <source src="/video/background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="container"
          style={{
            position: "absolute",
            top: "70%",
            paddingRight: "auto",
            transform: "translate(0%, -50%)",
            // paddingLeft: "5rem",
          }}
        >
          <h1
            className="hello"
            style={{
              fontSize: "30px",
              color: "white",
              paddingLeft: 0,
              backgroundImage: "url(/images/h1image.jpg)",
              backgroundClip: "text",
            }}
          >
            Top Tier Blockchain Development Company
          </h1>
          <p className="text-white hello2">Build Trust in Blockchain</p>
        </div>
      </div>

      <div className="container m-auto p-auto mt-5">
        <h3 className="mt-3 text-white text-center">
          Blockchain Development Process{" "}
        </h3>
        <h6 className="mt-3 text-white text-center">
          The blockchain development process adopts industry-best strategies,
          cutting-edge technologies, and innovative protocols.
        </h6>
      </div>

      <div className="container m-auto p-auto">
        <ConsultingProcess />
      </div>

      <div className="container m-auto p-auto my-5">
        <h3 className="mt-3 text-white text-center">
          Transforming Industries with Tailored
        </h3>
        <h3 className="mt-3 text-white text-center">
          Blockchain Development Services
        </h3>
        <h6 className="mt-3 text-white text-center">
          An impactful wave of technology that owns the power to boost your
          venture by 10x.
        </h6>
      </div>

      <div className="container row no-gutters m-auto p-auto">
        <Cards />
      </div>

      <div className="container m-auto p-auto my-5">
        <h3 className="mt-3 text-white text-center">
          Inspiring Innovation through Seamless IT Solutions{" "}
        </h3>
        <h6 className="mt-3 text-white text-center">
          As a top blockchain app development company, we assist enterprises in
          aligning blockchain
        </h6>
        <h6 className="mt-3 text-white text-center">
          platforms with their business model potential.
        </h6>
      </div>

      <div className="container row no-gutters m-auto p-auto">
        <div className="col-md-4 col-sm-12 py-5 text-white">
          <div>
            <h6>About</h6>
            <h2>TechsXpress</h2>
          </div>
          <div className="pt-5 counter_number1">
            <h1>150+</h1>
            <h4>Developers</h4>
          </div>
          <div className="pt-5 counter_number2">
            <h1>100+</h1>
            <h4>Projects Delivered</h4>
          </div>
        </div>
        <div className="col-md-8 col-sm-12">
          <div className="row no-gutters" style={{ height: "100vh" }}>
            <div className="col-6">
              <video
                autoPlay
                muted
                loop
                playsInline
                className="w-100 h-100 intro_video"
                style={{ objectFit: "cover" }}
              >
                <source src="/video/event.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-6">
              <img
                src="/images/eventImage.jpeg"
                alt="Your Image"
                className="w-100"
                style={{ objectFit: "cover", height: "100vh" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container m-auto p-auto mt-5">
        <h3 className="mt-3 text-white text-center">
          Generating lasting performance for mission critical priorities
        </h3>
      </div>
      <div style={{ backgroundColor: "#e9ecef" }} className="py-4">
        <Partners />
      </div>
      <div style={{ backgroundColor: "#e9ecef" }} className="py-4 mt-4">
        <Clients />
      </div> */}

      <div className="container m-auto p-auto">
        <h3 className="d-flex justify-content-center mt-5 text-white">
          Driving Ingenuity with Relentless Blockchain Innovation
        </h3>
        <Crytos />
      </div>
      {/* <div className="container m-auto p-auto">
        <h3 className="d-flex justify-content-center mt-5 text-white">
          Successful Projects We’ve Delivered
        </h3>
        <Projects />
      </div> */}
      <div className="container m-auto p-auto">
        <h3 className="d-flex justify-content-center mt-5 text-white">
          Featured Blogs{" "}
        </h3>
        <h6 className="d-flex justify-content-center text-white">
          Get complete understanding of modern technologies through our blogs.
        </h6>
        <Blogs />
      </div>
      <div className="container m-auto p-auto">
        <CustomersReviews />
      </div>
      <div
        className="container m-auto p-auto"
        style={{ backgroundColor: "#1c1c1e57" }}
      >
        <Form />
      </div>
      <div
        className="container m-auto p-auto"
        style={{ bottom: 0, right: "0" }}
      >
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
