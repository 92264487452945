import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
    EcommerceWebsite: [
    {
      heading: "E-commerce Websites ",
      subHeading:
        "We’re the expert ecommerce website designers who create stunning e-commerce platforms like Amazon, Flipkart to sell your products online effortlessly. We develop e-commerce sites that facilitate product listings, online transactions and much more for a successful ecommerce business growth. We develop B2B (Business to Business), B2C (Business to Consumer) and C2C ( Consumer to Consumer) sites based on your business model. ",
    },
  ],

  TravelingApplication: [
    {
      heading: "Traveling Application",
      subHeading:
        "In this internet era, travelers heavily rely on traveling apps to plan their travel, book their accommodation and travel to their destinations easily. If you’re looking for traveling application building services, then we can help you to attain your goal with our experienced app developers. We offer end-to-end custom app development which  helps you to achieve business growth in a short span.",
    }, 
  ],
  FoodDeliveryApplication: [
    {
      heading: "Food Delivery Application",
      subHeading:
        "Food industry is one the fattest and ever growing industries where everyday thousands of new businesses are ventured into the market. In order to compete with the existing players you need to standout from the crowd. We help you to create enchanting food delivery apps to meet your specific business goals and to offer flawless applications that connect the customers and business owners. Our team of developers helps build extraordinary food delivery applications at nominal costs.",
    }, 
  ],
  TaxiBookingApplications: [
    {
      heading: "Taxi Booking Applications",
      subHeading:
        "With our team of experienced developers, we build taxi booking applications like Ola, Uber and so on. In this fast moving era, everyone has no patience to wait to be served, they need everything within a fraction of second. We help you to build the different types of booking applications to meet your specific business requirement at an economic cost. We provide inbuilt features like GPS integration, custom app development, payment processor integration, fare calculation, user profiles creation and much more.",
    }, 
  ],
  DatingApps: [
    {
      heading: "Dating Apps",
      subHeading:
        "Online dating has become a current user trending industry where people meet and connect via online. Our team of expert developers, helps you to  build an engaging online dating application with tons of custom features that makes your app unique and engaging in every aspect to retain your users for a longer period. You can build custom online dating applications to meet your specific business goals effectively. Contact us for more information. ",
    }, 
  ],
  ServiceApplications: [
    {
      heading: "Service Applications",
      subHeading:
        "We have a dedicated team of developers to build a wide range of service applications according to your service type and business requirement. We develop service applications for all kinds of industries to drive business growth thereby engaging your customers by fulfilling their needs on time. We help you to build end-to-end service applications at an affordable price. Contact us today for consultation.",
    }, 
  ],
  GamingApplications: [
    {
      heading: "Gaming Applications",
      subHeading:
        "Gaming industry has become the most dynamic industry with lots of competition.In order to stand out from the crowd you need to develop an entertaining, engaging and to offer seamless user experience to your players. We help you achieve business excellence by building an outstanding gaming application. If you’re looking to create a mobile game, an online multiplayer experience, or an immersive VR game, our team has the expertise to bring your vision to life.",
    }, 
  ],
};

const Web2 = () => {
  const [selected, setSelected] = useState("EcommerceWebsite"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.EcommerceWebsite);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100">
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("EcommerceWebsite")}
          style={{
            backgroundColor:
              selected === "EcommerceWebsite" ? "#1c28c759" : "transparent",
          }}
        >
         Ecommerce Website
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("TravelingApplication")}
          style={{
            backgroundColor:
              selected === "TravelingApplication" ? "#1c28c759" : "transparent",
          }}
        >
          Traveling Application
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("FoodDeliveryApplication")}
          style={{
            backgroundColor:
              selected === "FoodDeliveryApplication" ? "#1c28c759" : "transparent",
          }}
        >
         Food Delivery Application
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("TaxiBookingApplications")}
          style={{
            backgroundColor:
              selected === "TaxiBookingApplications" ? "#1c28c759" : "transparent",
          }}
        >
          Taxi Booking Applications
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("DatingApps")}
          style={{
            backgroundColor:
              selected === "DatingApps" ? "#1c28c759" : "transparent",
          }}
        >
         Dating Apps
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("ServiceApplications")}
          style={{
            backgroundColor:
              selected === "ServiceApplications" ? "#1c28c759" : "transparent",
          }}
        >
         Service Applications
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("GamingApplications")}
          style={{
            backgroundColor:
              selected === "GamingApplications" ? "#1c28c759" : "transparent",
          }}
        >
         Gaming Applications
        </p> 
        
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-10 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Web2;
