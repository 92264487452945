import React from "react";
import '../styles/blogs.css'

const Blogs = () => {
  return (
    <div className="d-flex blg_flx wow slideInUp row">
      <div className="blg_bx text-white">
          <figure>
            <img
              alt="Blockchain in Cybersecurity"
              src="/images/bitcoins.jpeg"
              className="lazyloaded"
              decoding="async"
              id="MjAxMDoxNDQ=-1"
            />
            <div className="blg_cnt">
              <span className="blg_tim">Blog</span>
              <h5>Finance: The traditional system for cross-border payments is often slow, expensive, and lacks transparency.</h5>
              <div className="blg_authore">
                <span>
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
            </div>
          </figure>
      </div>

      <div className="blg_bx text-white">
          <figure>
            <img
              alt="Metaverse in Banking"
              src="/images/BMetaverse.jpeg"
              className="lazyloaded"
              decoding="async"
              id="MjAyNjoxNDE=-1"
            />
            <div className="blg_cnt">
              <span className="blg_tim">Blog</span>
              <h5>Real Estate: Blockchain facilitates secure data sharing, streamlines rental collections and payments to property owners.</h5>
              <div className="blg_authore">
                <span>
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
            </div>
          </figure>
      </div>

      <div className="blg_bx text-white">
          <figure>
            <img
              alt="Metaverse in Healthcare"
              src="/images/healthcare.jpeg"
              className="lazyloaded"
              decoding="async"
              id="MjA0MjoxNDQ=-1"
            />
            <div className="blg_cnt">
              <span className="blg_tim">Blog</span>
              <h5>Carbon Credit: This is to ensure that small-scale investors can efficiently participate in the process of fighting climate change.</h5>
              <div className="blg_authore">
                <span>
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
            </div>
          </figure>
      </div>
    </div>
  );
};

export default Blogs;
