import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  Govt: [
    {
      heading: "G2C",
      subHeading:
        "Improve existing G2C services with blockchain-driven intelligent analytics",
    },
    {
      heading: "Digital Identity",
      subHeading:
        "Secure digital identities with a decentralized digital identity management system",
    },
    {
      heading: "Government Data Storage",
      subHeading:
        "Utilize Blockchain-based data storage systems to store and secure data",
    },
    {
      heading: "E-Voting System",
      subHeading:
        "Securing the way e-voting works through decentralized blockchain technology",
    },
    {
      heading: "Government Regulatory Systems",
      subHeading:
        "Enhancing democratic, open, and self-reliance government regulatory system",
    },
  ],

  BFSI: [
    {
      heading: "CBDC",
      subHeading:
        "Leverage near real-time data with a monetary system to solidify banking systems",
    },
    {
      heading: "Trade Finance",
      subHeading:
        "Embrace greater speed, security, & transparency in transactions",
    },
    {
      heading: "Insurance",
      subHeading:
        "BFSI products that help cut costs, save resources, and stay compliant",
    },
    {
      heading: "KYC & AML",
      subHeading:
        "Streamline the KYC process and manage the entire user lifecycle",
    },
  ],
  Gaming: [
    {
      heading: "Game Development",
      subHeading:
        "Gear up for the future of game development, from character design to coding!",
    },
    {
      heading: "P2E Game Development",
      subHeading:
        "Let’s turn your gaming dreams into a reality with our expert team by your side. Join us to create the next big thing.",
    },
  ],
};

const Industries = () => {
  const [selected, setSelected] = useState("Govt"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.Govt);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Govt")}
          style={{
            backgroundColor:
              selected === "Govt" ? "#1c28c759" : "transparent",
          }}
        >
          Govt.
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("BFSI")}
          style={{
            backgroundColor:
              selected === "BFSI" ? "#1c28c759" : "transparent",
          }}
        >
          BFSI
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Gaming")}
          style={{
            backgroundColor:
              selected === "Gaming" ? "#1c28c759" : "transparent",
          }}
        >
          Gaming
        </p> 
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Industries