import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  Exchange: [
    {
      heading: "Custom Exchange",
      subHeading:
        "Develop your competitive enterprise-level custom exchange from scratch",
    },
    {
      heading: "White Label Exchange",
      subHeading:
        "Gear up your revenue stream using a ready-made white label exchange",
    },
    {
      heading: "Margin Trading Exchange",
      subHeading:
        "Secure and feature-rich margin trading exchange to disrupt the digital market",
    },
    {
      heading: "P2P Exchange",
      subHeading:
        "Use a smart contract-based Escrow system to foster reliable transactions",
    },
    {
      heading: "Decentralized Exchange",
      subHeading:
        "Reduce your time-to-market with a customized DEX exchange script solution",
    },
    {
      heading: "Arbitrage Bots",
      subHeading:
        "Elevate your trading strategies with the powerful arbitrage bots",
    },
    {
      heading: "Derivatives Exchange",
      subHeading:
        "Assured platform liquidity & returns for a huge pool of crypto investors",
    },
    {
      heading: "Centralized Exchange",
      subHeading:
        "Facilitate digital asset trading in a secure and convenient environment",
    },
    {
      heading: "Market Making Services",
      subHeading:
        "Intensify liquidity provisioning with perfect market making strategies",
    },

  ],

  Wallet: [
    {
      heading: "Digital Wallet",
      subHeading:
        "Robust & secure wallet solutions for storing & transferring digital assets",
    },
    {
      heading: "White Label Wallet",
      subHeading:
        "Ready-to-deploy white label wallet with high-grade security features",
    },
    {
      heading: "Super App Development",
      subHeading:
        "Unlock the power of an inventive super crypto app integrated with enticing revenue prospects.",
    },
    {
      heading: "Web3 Wallet",
      subHeading:
        "Access data in real time with trustless Web3 blockchain ecosystems",
    },
    {
      heading: "Multicurrency Wallet",
      subHeading:
        "Deploy wallets that are intuitive, user-friendly, secure & scalable",
    },
    {
      heading: "MPC Crypto Wallet",
      subHeading:
        "MPC wallet streamlines digital asset access & safeguards private keys from vulnerabilities.",
    },
    {
      heading: "Defi Wallet",
      subHeading:
        "A wallet that is fortified from hacks, & immune to cross-border regulations",
    },
    {
      heading: "TRON Wallet",
      subHeading:
        "Tron wallet hold private keys & TRX coins securely within itself.",
    },
    {
      heading: "NFT Wallet",
      subHeading:
        "Private key-based solutions to buy, sell and exchange non fungible tokens",
    },
  ],

  NFT: [
    {
      heading: "NFT Development",
      subHeading:
        "Build decentralized non-fungible tokens aligned with different business needs",
    },
    {
      heading: "White Label NFT Marketplace",
      subHeading:
        "Get a customized white label NFT marketplace with multi-platform support",
    },
    {
      heading: "NFT Music",
      subHeading:
        "Customer-centric marketplace for selling, trading and auctioning music NFTs",
    },
    {
      heading: "NFT Marketplace",
      subHeading:
        "Top-performing marketplaces for users to mint, sell, buy, and trade NFTs",
    },
    {
      heading: "NFT Loan",
      subHeading:
        "Facilitate your participation in revolutionized borrowing & lending world",
    },
    {
      heading: "Semi Fungible Token",
      subHeading:
        "Easy batch token transfers that support both NFTs and fungible tokens",
    },
    {
      heading: "NFT Gaming",
      subHeading:
        "Exclusive gameplay with rare digital assets like characters, tickets, weapons, & skins",
    },
    {
      heading: "NFT Generative Art",
      subHeading:
        "Future ready NFT generative art marketplace that supports pictures, videos and GIFs",
    },
    {
      heading: "NFT Art",
      subHeading:
        "Tokenize digital and physical assets into a one-of-a-kind, untransferable NFT",
    },
  ],

  DeFi: [
    {
      heading: "DeFi Development",
      subHeading:
        "Drive resiliency and facilitate trust with exceptional DeFi development",
    },
    {
      heading: "DeFi Lottery",
      subHeading:
        "Decentralized lottery ecosystem reinforced with greater security, and traceability",
    },
    {
      heading: "DAO Blockchain",
      subHeading:
        "100% transparent transactions, mandatory voting and handle services in a standardized way",
    },
    {
      heading: "Olympus DAO Development",
      subHeading:
        "Introduce unique economic and game-theoretic dynamics through staking and bonding",
    },
    {
      heading: "DeFi Yield Farming",
      subHeading:
        "Deliver business-oriented DeFi yield farming platforms that accomplish business goals",
    },
    {
      heading: "DeFi Staking",
      subHeading:
        "Staking software with market-leading features and institutional-grade security",
    },
    {
      heading: "DeFi Lending and Borrowing",
      subHeading:
        "Borrowing and lending platforms that fill the gap lagging in traditional banking",
    },
    {
      heading: "Dapp Development Company",
      subHeading:
        "Business growth with unique, innovative, secure and engaging applications",
    },
  ],

  Web3: [
    {
      heading: "Web 3.0 Development",
      subHeading:
        "Develop an en evolved web that is open, intelligent, and autonomous",
    },
    {
      heading: "Web3 Game Development",
      subHeading:
        "Amplify your gaming experience with the help of web 3.0",
    },
  ],
  DigitalBanking: [
    {
      heading: "Digital Banking",
      subHeading:
        "Digital bank with industry-leading features and state-of-the-art infrastructure",
    },
    {
      heading: "White Label Banking",
      subHeading:
        "Speed up your bank launch with state-of-the-art white label solutions",
    },
    {
      heading: "Payment Gateway",
      subHeading:
        "Get Customized Payment Gateway Solutions For Secured Transactions",
    },
    {
      heading: "P2P Lending",
      subHeading:
        "Tap into the billion-dollar lending market with a P2P lending platform",
    },
   
  ],
  CoinDevelopment: [
    {
      heading: "Launchpad Development",
      subHeading:
        "Build a launchpad to capitalize on the revenue generation opportunity",
    },
    {
      heading: "ICO Development",
      subHeading:
        "Maximize fundraising opportunities and drive innovation with ICO development",
    },
    {
      heading: "Coin/Token Development",
      subHeading:
        "Best services for crypto coin/token development to match your business goals",
    },
    {
      heading: "IDO Development",
      subHeading:
        "Create a coherent roadmap to navigate your IDO development journey",
    },
    {
      heading: "Stablecoin Development",
      subHeading:
        "Deploy gold-backed stablecoins to navigate your coin development journey",
    },
  ],
  TradingBots: [
    {
      heading: "Trading Bot",
      subHeading:
        "Build your AI trading bots to set your trades on Autopilot.",
    },
    {
      heading: "Flash Loan Arbitrage Bot",
      subHeading:
        "Explore flash loan arbitrage bots to conveniently execute your trading strategy",
    },
    {
      heading: "Arbitrage Bots",
      subHeading:
        "Elevate your trading strategies with the powerful arbitrage bots",
    },
  ],
};

const Solutions = () => {
  const [selected, setSelected] = useState("Exchange"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.Exchange);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Exchange")}
          style={{
            backgroundColor:
              selected === "Exchange" ? "#1c28c759" : "transparent",
          }}
        >
          Exchange
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Wallet")}
          style={{
            backgroundColor:
              selected === "Wallet" ? "#1c28c759" : "transparent",
          }}
        >
          Wallet
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("NFT")}
          style={{
            backgroundColor:
              selected === "NFT" ? "#1c28c759" : "transparent",
          }}
        >
          NFT
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("DeFi")}
          style={{
            backgroundColor:
              selected === "DeFi" ? "#1c28c759" : "transparent",
          }}
        >
          DeFi
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Web3")}
          style={{
            backgroundColor:
              selected === "Web3" ? "#1c28c759" : "transparent",
          }}
        >
          Web3
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("DigitalBanking")}
          style={{
            backgroundColor:
              selected === "DigitalBanking" ? "#1c28c759" : "transparent",
          }}
        >
          Digital Banking
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("CoinDevelopment")}
          style={{
            backgroundColor:
              selected === "CoinDevelopment" ? "#1c28c759" : "transparent",
          }}
        >
          Coin Development
        </p> 
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("TradingBots")}
          style={{
            backgroundColor:
              selected === "TradingBots" ? "#1c28c759" : "transparent",
          }}
        >
          Trading Bots
        </p> 
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Solutions