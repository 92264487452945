import React, { useState, useEffect, useRef } from "react";
import "../styles/header.css";
import About from "./headers/About";
import AI from "./headers/AI";
import Blockchain from "./headers/Blockchain";
import Consulting from "./headers/Consulting";
import Industries from "./headers/Industries";
import Metaverse from "./headers/Metaverse";
import Solutions from "./headers/Solutions";
import Web2 from "./headers/Web2";
import Marketing from "./headers/Marketing";

const Header = () => {
  const [blockchain, setBlockchain] = useState(false);
  const [ai, setAI] = useState(false);
  const [metaverse, setMetaverse] = useState(false);
  const [consulting, setConsulting] = useState(false);
  const [solutions, setSolutions] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [about, setAbout] = useState(false);
  const [web2, setWeb2] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const headerRef = useRef(null);
  const [mouseInside, setMouseInside] = useState(false);

  const handleMouseEnter = () => {
    setMouseInside(true);
  };

  const handleMouseLeave = () => {
    setMouseInside(false);
  };

  useEffect(() => {
    const handleMouseLeaveDocument = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target) && !mouseInside) {
        setBlockchain(false);
        setAI(false);
        setMetaverse(false);
        setConsulting(false);
        setSolutions(false);
        setIndustries(false);
        setAbout(false);
        setWeb2(false);
        setMarketing(false);
      }
    };

    document.addEventListener("mouseover", handleMouseLeaveDocument);
    return () => {
      document.removeEventListener("mouseover", handleMouseLeaveDocument);
    };
  }, [mouseInside]);

  const handleMouseEnterMenu = (setStateFunction) => {
    setBlockchain(false);
    setAI(false);
    setMetaverse(false);
    setConsulting(false);
    setSolutions(false);
    setIndustries(false);
    setAbout(false);
    setWeb2(false);
    setMarketing(false);
    setStateFunction(true);
  };

  return (
    <div>
      <nav
        ref={headerRef}
        className="navbar navbar-expand-lg fixed-top container mt-4 py-2 px-2 d-flex flex-column"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="container">
          <a
            className="navbar-brand"
            href="#"
            style={{ color: "black", fontSize: "26px", fontWeight: 600 }}
            onMouseEnter={() => {
              setBlockchain(false);
              setAI(false);
              setMetaverse(false);
              setConsulting(false);
              setSolutions(false);
              setIndustries(false);
              setAbout(false);
              setWeb2(false);
              setMarketing(false);
            }}
          >
            TechsXpress
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive" style={{ msOverflowX: 'auto', width: '100vw' }}>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active px-2">
                <a
                  className={`nav-link ${blockchain ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setBlockchain)}
                >
                  Blockchain
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${ai ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setAI)}
                >
                  AI
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${metaverse ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setMetaverse)}
                >
                  Metaverse
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${consulting ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setConsulting)}
                >
                  Consulting
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${web2 ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setWeb2)}
                >
                  Web2
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${marketing ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setMarketing)}
                >
                  Marketing
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${solutions ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setSolutions)}
                >
                  Solutions
                </a>
              </li>
              <li className="nav-item px-2">
                <a
                  className={`nav-link ${industries ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setIndustries)}
                >
                  Industries
                </a>
              </li>
              {/* <li className="nav-item px-2">
                <a
                  className={`nav-link ${about ? "text-dark" : ""}`}
                  href="#"
                  onMouseEnter={() => handleMouseEnterMenu(setAbout)}
                >
                  About US
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        {blockchain && <Blockchain />}
        {ai && <AI />}
        {metaverse && <Metaverse />}
        {consulting && <Consulting />}
        {solutions && <Solutions />}
        {industries && <Industries />}
        {about && <About />}
        {web2 && <Web2 />}
        {marketing && <Marketing />}
      </nav>
    </div>
  );
};

export default Header;









// import React, { useState, useEffect, useRef } from "react";
// import "../styles/header.css";
// import About from "./headers/About";
// import AI from "./headers/AI";
// import Blockchain from "./headers/Blockchain";
// import Consulting from "./headers/Consulting";
// import Industries from "./headers/Industries";
// import Metaverse from "./headers/Metaverse";
// import Solutions from "./headers/Solutions";
// import Web2 from "./headers/Web2";
// import Marketing from "./headers/Marketing";

// const Header = () => {
//   const [blockchain, setBlockchain] = useState(false);
//   const [ai, setAI] = useState(false);
//   const [metaverse, setMetaverse] = useState(false);
//   const [consulting, setConsulting] = useState(false);
//   const [solutions, setSolutions] = useState(false);
//   const [industries, setIndustries] = useState(false);
//   const [about, setAbout] = useState(false);
//   const [web2, setWeb2] = useState(false);
//   const [marketing, setMarketing] = useState(false);
//   const headerRef = useRef(null);
//   const [mouseInside, setMouseInside] = useState(false);

//   const handleMouseEnter = () => {
//     setMouseInside(true);
//   };

//   const handleMouseLeave = () => {
//     setMouseInside(false);
//   };

//   useEffect(() => {
//     const handleMouseLeaveDocument = (event) => {
//       if (headerRef.current && !headerRef.current.contains(event.target) && !mouseInside) {
//         setBlockchain(false);
//         setAI(false);
//         setMetaverse(false);
//         setConsulting(false);
//         setSolutions(false);
//         setIndustries(false);
//         setAbout(false);
//         setWeb2(false);
//         setMarketing(false);
//       }
//     };

//     document.addEventListener("mouseover", handleMouseLeaveDocument);
//     return () => {
//       document.removeEventListener("mouseover", handleMouseLeaveDocument);
//     };
//   }, [mouseInside]);

//   const handleMouseEnterMenu = (setStateFunction) => {
//     setBlockchain(false);
//     setAI(false);
//     setMetaverse(false);
//     setConsulting(false);
//     setSolutions(false);
//     setIndustries(false);
//     setAbout(false);
//     setStateFunction(true);
//     setWeb2(false);
//     setMarketing(false);
//   };

//   return (
//     <div>
//       <nav
//         ref={headerRef}
//         className="navbar navbar-expand-lg fixed-top container mt-4 py-2 px-2 d-flex flex-column"
//         style={{
//           backgroundColor: "white",
//           borderRadius: "5px",
//         }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <div className="container">
//           <a
//             className="navbar-brand"
//             href="#"
//             style={{ color: "black", fontSize: "26px", fontWeight: 600 }}
//             onMouseEnter={() => {
//               setBlockchain(false);
//               setAI(false);
//               setMetaverse(false);
//               setConsulting(false);
//               setSolutions(false);
//               setIndustries(false);
//               setAbout(false);
//               setWeb2(false);
//               setMarketing(false);
//             }}
//           >
//             TechsXpress
//           </a>
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarResponsive"
//             aria-controls="navbarResponsive"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" id="navbarResponsive" style={{ msOverflowX: 'auto', width: '100vw' }}>
//             <ul className="navbar-nav ms-auto">
//               <li className="nav-item active px-2">
//                 <a
//                   className={`nav-link ${blockchain ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setBlockchain)}
//                 >
//                   Blockchain
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${ai ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setAI)}
//                 >
//                   AI
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${metaverse ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setMetaverse)}
//                 >
//                   Metaverse
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${consulting ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setConsulting)}
//                 >
//                   Consulting
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${ai ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setWeb2)}
//                 >
//                   Web2
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${ai ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setMarketing)}
//                 >
//                   Marketing
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${solutions ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setSolutions)}
//                 >
//                   Solutions
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${industries ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setIndustries)}
//                 >
//                   Industries
//                 </a>
//               </li>
//               <li className="nav-item px-2">
//                 <a
//                   className={`nav-link ${about ? "text-dark" : ""}`}
//                   href="#"
//                   onMouseEnter={() => handleMouseEnterMenu(setAbout)}
//                 >
//                   About US
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//         {blockchain && <Blockchain />}
//         {ai && <AI />}
//         {metaverse && <Metaverse />}
//         {consulting && <Consulting />}
//         {solutions && <Solutions />}
//         {industries && <Industries />}
//         {about && <About />}
//         {web2 && <Web2/>}
//         {marketing && <Marketing/>}
//       </nav>
//     </div>
//   );
// };

// export default Header;
