import React from "react";
// import Container from "react-bootstrap/container";
// import Row from "react-bootstrap/row";
// import Col from "react-bootstrap/col";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Image from "react-bootstrap/image";
import "../styles/reviews.css";

const CustomersReview = () => {
  const options = {
    margin: 15,
    responsiveClass: true,
    dots: false,
    nav: true,
    loop: true,
    // autoplay: true,
    // autoplayTimeout: 3000,
    // autoplaySpeed: 1500,
    autoplayHoverPause: true,
    navText: [
      "<i class='fa fa-angle-left' aria-hidden='true'></i>",
      "<i class='fa fa-angle-right' aria-hidden='true'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      786: {
        items: 1,
        dots: true,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
  return (
    <div id="customers-review">
      <div className="container my-4 py-4 px-lg-4">
        <div className="justify-content-center row">
          < div className="text-center col-12">
            <h2 className="mt-4 mb-sm-3 text-white">What Our Customers Say</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <OwlCarousel
              className="owl-theme"
              id="CustomersReview"
              {...options}
            >
              <div className="item">
                <div className="box pt-5 rounded-5 row">
                  <div className="text-center col-4">
                    <div className="text-center">
                      <div className="user">
                        <img
                          loading="lazy"
                          src="/images/stan.jpg"
                          alt="Dejanl Stankovic"
                          className="img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content col-8 text-white">
                    <p className="mb-5 pb-0">
                      Fast rising app development company in Blockchain.
                      Performing best in all aspects. The organization is rich
                      with dedicated and hardworking people making wonders in
                      work. Appreciable work culture. Keep growing Techs Xpress!
                    </p>
                    <h2 className="mt-0">Dejanl Stankovich</h2>
                    <div className="start-rating d-flex">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="box pt-5 rounded-5 row">
                  <div className="text-center col-4">
                    <div className="text-center">
                      <div className="user">
                        <img
                          loading="lazy"
                          src="/images/marfer.jpg"
                          alt="Marfer Quikastava"
                          className="img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content col-8 text-white">
                    <p className="mb-5 pb-0">
                      I must say deciding to get app development service from
                      Tech Xpress is absolutely a right decision, even after
                      with a complicated app idea the team developed the app in
                      perfect steps in a really smooth way and delivered my app
                      within the time frame as discussed between us. Thankyou
                      Techs Xpress Team for developing my app with perfect UI/UX
                      as I wanted !!!! Highly recommended.
                    </p>
                    <h2 className="mt-0">Marfer Quikastava</h2>
                    <div className="start-rating d-flex">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CustomersReview);
