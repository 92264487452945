import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  Consulting: [
    {
      heading: "Blockchain Consulting Company",
      subHeading:
        "Ours is a blockchain consulting and solution provider company. You idealize, we realize!",
    },
    {
      heading: "Web3 Consulting Company",
      subHeading:
        "Creating Web3 ecosystems for leveraging the benefits of blockchain technology",
    },
    {
      heading: "Metaverse Consulting Company",
      subHeading:
        "Metaverse consulting services to guide you on the right path",
    },
    {
      heading: "AI Consulting Company",
      subHeading:
        "Our motive is to fully realise business benefits using our expertise in AI, the transformative technology.",
    },
    {
      heading: "DeFi Consulting Services",
      subHeading:
        "Maximize your DeFi project’s success with tailored DeFi consulting services. Unlock new business opportunities.",
    },
    {
      heading: "DAO Consulting Services",
      subHeading:
        "Get goal-specific DAO consulting services from our industry experts",
    },

  ],

  MarketingService: [
    {
      heading: "Marketing As A Service",
      subHeading:
        "We deliver tailor-made and agile marketing solutions that unlock your project’s full potential.",
    }
    
    
  ],
};

const Consulting = () => {
  const [selected, setSelected] = useState("Consulting"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.Consulting);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Consulting")}
          style={{
            backgroundColor:
              selected === "Consulting" ? "#1c28c759" : "transparent",
          }}
        >
          Consulting
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("MarketingService")}
          style={{
            backgroundColor:
              selected === "MarketingService" ? "#1c28c759" : "transparent",
          }}
        >
          Marketing As A Service
        </p> 
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Consulting