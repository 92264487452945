import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
    LeadGeneration: [
    {
      heading: "Lead Generation",
      subHeading: "We are a team of professionals who will help you to run your business by generating high quality leads. We strategize the lead generation process by identifying the target audience and marketplace. We implement different strategies from SEO, Social media marketing, Pay Per Click (PPC) and Email Marketing to grow your business and to get the ROI for sure in no time. ",
    },
  ],

  SEOServices: [
    {
      heading: "SEO Services",
      subHeading: "Search Engine Optimization (SEO) helps you to rank your website in the top positions in SERP through organic ways of link building strategies. SEO will generate quality leads to your business in the long run without spending any single penny from your pocket. Our team of SEO experts will achieve the organic ranking for the targeted keywords within the stipulated time frame. ",
    }, 
  ],
  PPCServices: [
    {
      heading: "PPC Services",
      subHeading: "Pay per click (PPC) is the model where you will pay for each click which is made by the end user. Popular PPC platforms are Google Ads and Bing Ads in which you can run targeted campaigns for different business goals like lead generation, brand awareness and many more. With PPC, you can compete with your competitors instantly. Our PPC experts are here to help you to generate quality leads by creating high performing campaigns for assured business growth.",
    }, 
  ],
  SocialMediaMarketingServices: [
    {
      heading: "Social Media Marketing Services",
      subHeading: "Social media marketing is the high performing niche in digital marketing because it includes the popular social media platforms such as Instagram, Facebook, X and so on where each platform has billions of users who are actively spending time. We can target the audience on social platforms to promote your brand and to generate leads for your business. Our team of social media experts are here to help you out today!",
    }, 
  ],
  WebDesigningServices: [
    {
      heading: "Web Designing Services",
      subHeading: "Websites become an integral part of any business. It acts as a bridge between the end user and business owner. We are a team of professional website builders who develop eye-catching websites to offer a seamless user experience to drive users to perform a set of activities like lead generation, brand building and customer retention. We develop all types of websites like static, dynamic, CMS based sites and so on. It helps you build credibility and improves business growth. ",
    }, 
  ],
  EmailMarketing: [
    {
      heading: "Email Marketing",
      subHeading: "Email marketing is the way of communicating your personalized message directly to their inbox. We help you to segment your targeted audience to achieve a good open rate and click rate. Our email marketing professionals help you to improve your customer engagement, customer retention and assure you to get a good Return on Investment (ROI).",
    }, 
  ],
  ColdCalling: [
    {
      heading: "Cold Calling",
      subHeading: "Cold calling is the direct way of reaching out to potential decision makers directly and closing deals instantly. Even Though we are in a fast paced world, cold calling is still one of the great ways to generate leads for your business. Our team of experts will help you to reach your sales goals effortlessly.",
    }, 
  ],
  VideoCreationServices: [
    {
      heading: "Video Creation Services",
      subHeading: "Videos are a great engagement tool to instantly hook the audience by communicating specific messages with engaging visuals. Our team of professional video editors helps you to create different types of videos such as 2D and 3D videos. Reach out to us to create compelling and engaging videos that elevates your business to the next level.",
    }, 
  ],
};

const Marketing = () => {
  const [selected, setSelected] = useState("LeadGeneration"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.LeadGeneration);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100">
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("LeadGeneration")}
          style={{
            backgroundColor:
              selected === "LeadGeneration"
                ? "#1c28c759"
                : "transparent",
          }}
        >
          Lead Generation
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("SEOServices")}
          style={{
            backgroundColor:
              selected === "SEOServices" ? "#1c28c759" : "transparent",
          }}
        >
          SEO Services
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("PPCService")}
          style={{
            backgroundColor:
              selected === "PPCService" ? "#1c28c759" : "transparent",
          }}
        >
          PPC Service
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("SocialMediaMarketingServices")}
          style={{
            backgroundColor:
              selected === "SocialMediaMarketingServices" ? "#1c28c759" : "transparent",
          }}
        >
          Social Media Marketing Services
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("WebDesigningServices")}
          style={{
            backgroundColor:
              selected === "WebDesigningServices" ? "#1c28c759" : "transparent",
          }}
        >
          Web Designing Services
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("EmailMarketing")}
          style={{
            backgroundColor:
              selected === "EmailMarketing" ? "#1c28c759" : "transparent",
          }}
        >
          Email Marketing
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("ColdCalling")}
          style={{
            backgroundColor:
              selected === "ColdCalling" ? "#1c28c759" : "transparent",
          }}
        >
          Cold Calling
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("VideoCreationServices")}
          style={{
            backgroundColor:
              selected === "VideoCreationServices" ? "#1c28c759" : "transparent",
          }}
        >
          Video Creation Services
        </p>
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-10 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marketing;
