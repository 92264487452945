import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  ArtificialIntelligence: [
    {
      heading: "AI ML solutions",
      subHeading:
        "Capitalize on the potential of AI & ML for strategic business operations",
    }
  ],

  GenerativeAI: [
    {
      heading: "Generative AI Development",
      subHeading:
        "Stay ahead of the AI curve with our generative AI development services",
    },
  ],
};

const AI = () => {
  const [selected, setSelected] = useState("ArtificialIntelligence"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.ArtificialIntelligence);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("ArtificialIntelligence")}
          style={{
            backgroundColor:
              selected === "ArtificialIntelligence" ? "#1c28c759" : "transparent",
          }}
        >
          Artificial Intelligence
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("GenerativeAI")}
          style={{
            backgroundColor:
              selected === "GenerativeAI" ? "#1c28c759" : "transparent",
          }}
        >
          Generative AI
        </p> 
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AI;
