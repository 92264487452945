import React, { useState } from "react";
import "../styles/form.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (allows for optional + and spaces)
    const phoneRegex =
      /^(\+?\d{1,4}[\s-]?)?\(?\d{1,4}\)?[\s-]?\d{1,4}[\s-]?\d{1,9}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    // Validate form fields
    if (!validateEmail(email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!validatePhone(phone)) {
      formErrors.phone = "Please enter a valid phone number.";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Replace these with your actual EmailJS credentials
    const serviceId = "service_yq3tfgn";
    const templateId = "template_p5klbgi";
    const publicKey = "xnhZlz83K0j4Cgi8A";

    const templateParams = {
      from_firstname: firstName,
      from_lastname: lastName,
      from_email: email,
      from_phone: phone,
      to_name: "Chirag",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.success("Email Sent Successfully!");
        console.log("Email Sent Successfully!", response);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setErrors({});
      })
      .catch((error) => {
        toast.error("Failed to send Email!");
        console.log("FAILED...", error);
      });
  };

  return (
    <div>
      <div className="contact_us_green text-white">
        <div className="responsive-container-block big-container">
          <div className="responsive-container-block container">
            <div
              className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line"
              id="i69b-2"
            >
              <form onSubmit={handleSubmit} className="form-box">
                <div className="container-block form-wrapper">
                  <div className="head-text-box wide">
                    <p className="text-blk contactus-head">Contact us</p>
                    <p className="text-blk contactus-subhead">
                      counseling by specialists <br />
                      Detail project assessment <br />
                      On-call assistance
                    </p>
                  </div>
                  <div className="responsive-container-block">
                    <div
                      className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6"
                      id="i10mt-6"
                    >
                      <p className="text-blk input-title">NAME</p>
                      <input
                        className="input"
                        id="ijowk-6"
                        name="FirstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">COUNTRY</p>
                      <input
                        className="input"
                        id="indfi-4"
                        name="LastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">EMAIL</p>
                      <input
                        className={`input ${errors.email ? "error" : ""}`}
                        id="ipmgh-6"
                        name="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                      />
                      {errors.email && (
                        <p className="error-text">{errors.email}</p>
                      )}
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">PHONE NUMBER</p>
                      <input
                        className={`input ${errors.phone ? "error" : ""}`}
                        id="imgis-5"
                        name="PhoneNumber"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="tel"
                      />
                      {errors.phone && (
                        <p className="error-text">{errors.phone}</p>
                      )}
                    </div>
                    <div
                      className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                      id="i634i-6"
                    >
                      <p className="text-blk input-title">
                        WHAT DO YOU HAVE IN MIND (REQUIREMENTS)
                      </p>
                      <textarea
                        className="textinput"
                        id="i5vyy-6"
                        placeholder="Please enter here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="submit" className="submit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10"
              id="ifgi"
            >
              <div className="container-box">
                <div className="text-content">
                  <p className="text-blk contactus-head">Contact us</p>
                  <p className="text-blk contactus-subhead">
                    counseling by specialists <br />
                    Detail project assessment <br />
                    On-call assistance
                  </p>
                </div>
                <div className="workik-contact-bigbox">
                  <div className="workik-contact-box">
                    <div className="phone text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg"
                        alt="Phone"
                      />
                      <p className="contact-text">+91 87388 25090</p>
                    </div>
                    <div className="address text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg"
                        alt="Email"
                      />
                      <p className="contact-text">info@techsxpress.com</p>
                    </div>
                    <div className="mail text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg"
                        alt="Address"
                      />
                      <p className="contact-text">Golf Green, Noida </p>
                    </div>
                  </div>
                  <div className="social-media-links">
                    <a href="">
                      <img
                        className="social-svg"
                        id="is9ym"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg"
                        alt="Mail"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/techs-xpress/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="social-svg"
                        id="i706n"
                        src="/images/LinkedIn.svg"
                        alt="LinkedIn"
                      />
                    </a>

                    <a href="">
                      <img
                        className="social-svg"
                        id="ib9ve"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg"
                        alt="Instagram"
                      />
                    </a>
                    <a href="">
                      <img
                        className="social-svg"
                        id="ie9fx"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg"
                        alt="Facebook"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
