import React from "react";
import "../styles/crypto.css";

const Cryptos = () => {
  return (
    <section className="section mcb-section mfn-default-section mcb-section-77c3ca4b3 nft-token-icons desk1 hide-mobile default-width text-white">
      <div className="mcb-background-overlay"></div>
      <div className="section_wrapper mfn-wrapper-for-wraps mcb-section-inner mcb-section-inner-77c3ca4b3">
        <div className="flex-container">
          <div className="wrap mcb-wrap mcb-wrap-225d6b1f0 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-225d6b1f0 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-73ebe2251 one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-73ebe2251 mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2023/03/Ethereum-Smart.png"
                        alt="Ethereum Smart"
                        width="37"
                        height="60"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Ethereum</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-fbd3a20a9 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-fbd3a20a9 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-53dfb2143 one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-53dfb2143 mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2022/09/polygon-matic-logo-1.png"
                        alt="Polygon"
                        width="86"
                        height="86"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Polygon</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-c1a879846 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-c1a879846 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-40acbe07e one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-40acbe07e mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Binance-1.png.webp"
                        alt="Binance"
                        width="82"
                        height="86"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Binance</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-5ab0e23a8 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-5ab0e23a8 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-bdbc56b7d one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-bdbc56b7d mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Solana-3.png.webp"
                        alt="Solana"
                        width="74"
                        height="58"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Solana</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-46de1c054 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-46de1c054 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-efee60700 one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-efee60700 mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        src="https://www.antiersolutions.com/wp-content/uploads/2024/07/Skale.svg"
                        className="attachment-full size-full"
                        alt="Skale"
                        loading="lazy"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Skale</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-e5a4a56a1 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-e5a4a56a1 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-9136ccdef one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-9136ccdef mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2022/04/tron.svg"
                        alt="Tron"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Tron</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap mcb-wrap mcb-wrap-2825fb9d6 one-sixth tablet-one-sixth laptop-one-sixth mobile-one mfn-item-custom-width">
            <div className="mcb-wrap-inner mcb-wrap-inner-2825fb9d6 mfn-module-wrapper mfn-wrapper-for-wraps">
              <div className="mcb-wrap-background-overlay"></div>
              <div className="column mcb-column mcb-item-ef95d6c77 one laptop-one tablet-one mobile-one column_icon_box_2">
                <div className="mcb-column-inner mfn-module-wrapper mcb-column-inner-ef95d6c77 mcb-item-icon_box_2-inner">
                  <div className="mfn-icon-box mfn-icon-box-top mfn-icon-box-center">
                    <div className="icon-wrapper">
                      <img
                        className="scale-with-grid"
                        src="https://www.antiersolutions.com/wp-content/uploads/2022/04/avalanche-avax-logo-1.svg"
                        alt="Avalanche"
                      />
                    </div>
                    <div className="desc-wrapper">
                      <h4 className="title label">Avalanche</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cryptos;
