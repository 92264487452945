import React from "react";
import "../styles/consulting.css";

const consultingProcess = () => {
  return (
    <>
      <div
        className="ftr_ie_cnt container mb-5 video "
        style={{ height: "600px" }}
      >
        <div className="ftr_lt_img ">
          <video loop autoPlay muted playsInline style={{ width: "100%" }}>
            <source src="/video/consulting.mp4" type="video/mp4" />
          </video>
        </div>
        <ul
          className="ftr_rt_cnt"
          style={{ height: "600px", overflow: "auto" }}
        >
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">Understand Project Requirement</h4>
            </div>
            <p>
              Our consultants will first study your blockchain project
              requirements, appraise the market, allocate resources, create a
              development model, and so on.
            </p>
          </li>
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">Layer chain Protocol Selection</h4>
            </div>
            <p>
              Following that, we choose the appropriate blockchain network
              (Hyperledger or Ethereum), its type (public or private),
              technological frameworks, and so on.
            </p>
          </li>
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">Infrastructure Establishment</h4>
            </div>
            <p>
              Blockchain core components such as user interfaces, administrative
              and dashboard capabilities, smart contracts, tokens, dApps,
              storage systems, security protocols, and so on are either created
              or incorporated.
            </p>
          </li>
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">Functionalities Integration </h4>
            </div>
            <p>
              The network incorporates cutting-edge features and capabilities
              that naturally correspond with the blockchain project
              requirements, ensuring stability and ease of usage.
            </p>
          </li>
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">QA & Code Review</h4>
            </div>
            <p>
              Following that, the blockchain network is examined, and smart
              contracts are audited to eliminate vulnerabilities and defects,
              guaranteeing that it operates without error.
            </p>
          </li>
          <li>
            <div className="ftr_hd">
              <h4 className="text-white">Maintenance Support</h4>
            </div>
            <p>
              The deployed blockchain platform is updated with advanced
              functionality in response to maintenance requests from the client.
            </p>
          </li>
        </ul>
      </div>

      <div className="row container m-auto p-auto datacards">
        <div className="hid col-12">
          <h4 className="text-white">Understand Project Requirement</h4>
          <p>
            Our consultants will first study your blockchain project
            requirements, appraise the market, allocate resources, create a
            development model, and so on.
          </p>
        </div>
        <div className="hid">
          <h4 className="text-white">Layer chain Protocol Selection</h4>
          <p>
            Following that, we choose the appropriate blockchain network
            (Hyperledger or Ethereum), its type (public or private),
            technological frameworks, and so on.
          </p>
        </div>
        <div className="hid">
          <h4 className="text-white">Infrastructure Establishment</h4>
          <p>
            Blockchain core components such as user interfaces, administrative
            and dashboard capabilities, smart contracts, tokens, dApps, storage
            systems, security protocols, and so on are either created or
            incorporated.
          </p>
        </div>
        <div className="hid">
          <h4 className="text-white">Functionalities Integration </h4>
          <p>
            The network incorporates cutting-edge features and capabilities that
            naturally correspond with the blockchain project requirements,
            ensuring stability and ease of usage.
          </p>
        </div>
        <div className="hid">
          <h4 className="text-white">QA & Code Review</h4>
          <p>
            Following that, the blockchain network is examined, and smart
            contracts are audited to eliminate vulnerabilities and defects,
            guaranteeing that it operates without error.
          </p>
        </div>
        <div className="hid">
          <h4 className="text-white">Maintenance Support</h4>
          <p>
            The deployed blockchain platform is updated with advanced
            functionality in response to maintenance requests from the client.
          </p>
        </div>
      </div>
    </>
  );
};

export default consultingProcess;
