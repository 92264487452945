import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  Metaverse: [
    {
      heading: "Metaverse Development",
      subHeading:
        "End-to-end solutions to facilitate your entry and participation in the metaverse",
    },
    {
      heading: "Metaverse App Development",
      subHeading:
        "Tap into the metaverse with enterprise-focused application development",
    },
    {
      heading: "Metaverse Events",
      subHeading: "Organize unforgettable metaverse events using our expertise",
    },
    {
      heading: "Metaverse Game",
      subHeading:
        "Bring About a Revolution in the World of Gaming with the Metaverse",
    },
    {
      heading: "Metaverse Avatar Development",
      subHeading:
        "Personalize and create cartoon avatar using our craftmanship",
    },
    {
      heading: "Build in Metaverse",
      subHeading:
        "Create seamless experiences across leading third party metaverse platforms",
    },
  ],

  MetaverseIndustries: [
    {
      heading: "Metaverse In The Healthcare",
      subHeading:
        "Deliver cost-effective treatments and improve healthcare outcomes with Metaverse",
    },
    {
      heading: "Metaverse In The Manufacturing",
      subHeading:
        "Empower design engineers to collaborate and create visual designs remotely",
    },
    {
      heading: "Metaverse For Education",
      subHeading:
        "Enter into the future of learning by implementing metaverse in education",
    },
    {
      heading: "Metaverse in Ecommerce",
      subHeading:
        "Accelerate your business with our cutting-edge Metaverse e-commerce platform development.",
    },
    {
      heading: "Metaverse in Real Estate",
      subHeading: "Unlock a new dimension of real estate with Metaverse",
    },
    {
      heading: "Metaverse Casino Game",
      subHeading:
        "Transforming Casino Gaming for the Metaverse Era with Virtual Jackpots",
    },
    {
      heading: "Metaverse In Food Industry",
      subHeading:
        "Dive into the exciting world of Metaverse restaurants with our team",
    },
    {
      heading: "Metaverse 3D Space",
      subHeading:
        "Bringing imagination to life in the digital realm with immersive virtual worlds",
    },
    {
      heading: "Metaverse Virtual Office",
      subHeading:
        "Experience the evolution of work with our futuristic metaverse working spaces",
    },
  ],
};

const Metaverse = () => {
  const [selected, setSelected] = useState("Metaverse"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.Metaverse);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Metaverse")}
          style={{
            backgroundColor:
              selected === "Metaverse"
                ? "#1c28c759"
                : "transparent",
          }}
        >
          Metaverse
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("MetaverseIndustries")}
          style={{
            backgroundColor:
              selected === "MetaverseIndustries" ? "#1c28c759" : "transparent",
          }}
        >
          Metaverse Industries{" "}
        </p>
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender?.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Metaverse;
