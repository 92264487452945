import React, { useState } from "react";
import "../../styles/blockchain.css";

const data = {
  Blockchain: [
    {
      heading: "Blockchain Development",
      subHeading:
        "Tap into the potential of blockchain technology with our strategic blockchain services",
    },
    {
      heading: "Blockchain Game Development",
      subHeading:
        "Elevate your gaming venture with specialized blockchain game development solutions",
    },
    {
      heading: "Blockchain in Identity Management",
      subHeading:
        "Transparent and flawless indentity management with blockchain",
    },
  ],
  BlockchainFrameWorks: [
    {
      heading: "Parity Substrate",
      subHeading:
        "Check out the opportunities provided by substrate blockchain development",
    },
    {
      heading: "OP Stack",
      subHeading:
        "Our OP Stack solutions drive your digital infrastructure to success.",
    },
    {
      heading: "Arbitrum Orbit",
      subHeading:
        "Unlock efficiency and scalability with our Arbitrum Orbit solution today!",
    },
    {
      heading: "polkadot Parachains",
      subHeading:
        "Launch customizable chains with Polkadot Parachains efficiently",
    },
    {
      heading: "Avalanche Subnets",
      subHeading:
        "Achieve Unparalleled Blockchain Scalability with Avalanche Subnets",
    },
    {
      heading: "Cosmos SDK ",
      subHeading:
        "Develop custom chains with Cosmos SDK to boost your business growth",
    },
    {
      heading: "Polygon zkEVM",
      subHeading: "Enhance Ethereum Scalability with Polygon zkEVM",
    },
    {
      heading: "zkSync Hyperchains",
      subHeading:
        "Explore zkSync Hyperchains: Interoperable and Efficient Blockchain Solutions",
    },
  ],

  Layer1Layer2: [
    {
      heading: "BSC",
      subHeading:
        "Use BSC as the right option for decentralized application development",
    },
    {
      heading: "Solana",
      subHeading:
        "Empower decentralized application development in a more efficient manner",
    },
    {
      heading: "Cardano",
      subHeading:
        "Develop & deploy fast, scalable, and eco-friendly dApps with Cardano blockchain",
    },
    {
      heading: "Ethereum",
      subHeading:
        "Launch next-gen secure and scalable platforms with Ethereum Blockchain solutions",
    },
    {
      heading: "Polkadot",
      subHeading:
        "Cover data across public, private, open & permission-less blockchain networks",
    },
  ],
  Enterprise: [
    {
      heading: "Supply Chain",
      subHeading:
        "Total visibility, transparency, & full-asset control across a supply chain network",
    },
    {
      heading: "Transport and Logistics",
      subHeading:
        "Embrace augmented security, trust in data, and logistics management",
    },
    {
      heading: "Entertainment",
      subHeading:
        "Achieve better visibility across content creation and distribution lifecycle",
    },
    {
      heading: "Education",
      subHeading:
        "Improve accessibility, credibility, & transparency in the education industry",
    },
    {
      heading: "Finance",
      subHeading:
        "Bring greater security, transparency, and speed to financial transactions",
    },
    {
      heading: "Healthcare",
      subHeading:
        "Eliminate issues of fraud and expensive treatments in healthcare",
    },
    {
      heading: "Real Estate",
      subHeading:
        "Minimize data silos and ensure transparency at abbreviated costs",
    },
    {
      heading: "Procure-to-Pay",
      subHeading:
        "Enhance the management of goods, services & resources with PTP",
    },
    {
      heading: "Carbon Credits",
      subHeading:
        "Better transparency, access & standardization for the carbon markets",
    },
  ],
  SmartContract: [
    {
      heading: "Smart Contract Development",
      subHeading:
        "Achieve business automation with advanced smart contract development",
    },
    {
      heading: "Smart Contract Audit",
      subHeading:
        "Audit smart contracts for errors, security flaws, & compilation issues",
    },
  ],
  Tokenization: [
    {
      heading: "Asset Tokenization",
      subHeading:
        "Transfer ownership & storage speedily without any centralized finance entity",
    },
    {
      heading: "Asset Management",
      subHeading:
        "Steer funds in high-performing asset tokens and digital products across exchanges",
    },
    {
      heading: "Real Estate Tokenization",
      subHeading:
        "Transforming Property Ownership with Real Estate Tokenization",
    },
  ],
  Whitepaper: [
    {
      heading: "White Paper Development",
      subHeading:
        "Build a strong foundation for your project with a mission-driven white paper",
    },
  ],
};

const Blockchain = () => {
  const [selected, setSelected] = useState("Blockchain"); // State for selected item
  const [dataToRender, setDataToRender] = useState(data.Blockchain);

  const handler = (key) => {
    if (data[key]) {
      setDataToRender(data[key]);
      setSelected(key);
    } else {
      setDataToRender([]);
    }
  };

  return (
    <div className="row w-100" style={{minHeight:'370px'}}>
      <div className="col-3 py-2">
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Blockchain")}
          style={{
            backgroundColor:
              selected === "Blockchain" ? "#1c28c759" : "transparent",
          }}
        >
          Blockchain
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("BlockchainFrameWorks")}
          style={{
            backgroundColor:
              selected === "BlockchainFrameWorks" ? "#1c28c759" : "transparent",
          }}
        >
          Blockchain Frameworks
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Layer1Layer2")}
          style={{
            backgroundColor:
              selected === "Layer1Layer2" ? "#1c28c759" : "transparent",
          }}
        >
          Layer 1 & Layer 2 Solutions
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Enterprise")}
          style={{
            backgroundColor:
              selected === "Enterprise" ? "#1c28c759" : "transparent",
          }}
        >
          Enterprise Blockchain
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("SmartContract")}
          style={{
            backgroundColor:
              selected === "SmartContract" ? "#1c28c759" : "transparent",
          }}
        >
          Smart Contract
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Tokenization")}
          style={{
            backgroundColor:
              selected === "Tokenization" ? "#1c28c759" : "transparent",
          }}
        >
          Tokenization
        </p>
        <p
          className={`mb-2 text-black border px-2 py-2 cursor-pointer`}
          onMouseEnter={() => handler("Whitepaper")}
          style={{
            backgroundColor:
              selected === "Whitepaper" ? "#1c28c759" : "transparent",
          }}
        >
          Whitepaper
        </p>
      </div>
      <div className="col-9 py-4">
        <div className="row">
          {dataToRender.map((item, index) => (
            <div key={index} className="col-4 mega-menu-title">
              <div style={{ fontWeight: 600 }}>{item.heading}</div>
              <div style={{ fontSize: "12px" }}>{item.subHeading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
