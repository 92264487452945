import React from "react";
import "../styles/cards.css";

const Cards = () => {
  const Bcards = [
    {
      imageUrl: "/images/finance.jpeg",
      title: "Blockchain in Finance",
      description:
        "Smart contracts provide speedier transaction execution while maintaining accuracy, improving cross-border payments, transaction transparency, and strengthening your digital reputation.",
    },
    {
      imageUrl: "/images/education.jpeg",
      title: "Blockchain in Education",
      description:
        "Tech Xpress blockchain services can help educational institutions protect student data while also giving students authority over their academic identity, credentials, honors, and certifications.",
    },
    {
      imageUrl: "/images/health.jpeg",
      title: "Blockchain in Healthcare",
      description:
        "Smart contracts can help to simplify medicinal medication tracing and medical record management. Also, enable nationwide interoperability and automate medical claim verification.",
    },
    {
      imageUrl: "/images/supplyChain.jpeg",
      title: "Blockchain in Supply Chain",
      description:
        "Create a master ledger among trade parties, use smart contracts to reduce bad invoices, and efficiently provide a shared trail of record to different partners.",
    },
    {
      imageUrl: "/images/metaverse.jpeg",
      title: "Blockchain in Metaverse",
      description:
        "The Ethereum blockchain technologies, when combined with Hyperledge and R3 Corda, will allow your business grow in tandem with technological demands. Examine decentralization with care!",
    },
    {
      imageUrl: "/images/insurance.jpeg",
      title: "Blockchain in Insurance",
      description:
        "Create an encrypted data ledger and make available certified reputable and credible information to your users. Complete the terms and connect the insurer and insured to automate their payments quickly.",
    },
    {
      imageUrl: "/images/retail.jpeg",
      title: "Blockchain in Retail",
      description:
        "Comfort inventory tracking to streamline consumer and business-to-business payments. Protect your customers' privacy with tamper-proof, decentralized ledger data security that is highly efficient.",
    },
    {
      imageUrl: "/images/derivative.jpeg",
      title: "Blockchain in Derivative Trading",
      description:
        "Take advantage of a secure crypto derivative trading platform that includes Future Contracts and Perpetual Contracts, which allow users to profit on market predictions, as well as many other features.",
    },
    {
      imageUrl: "/images/gaming.jpeg",
      title: "Blockchain in Gaming",
      description:
        "Enjoy original gaming versions by removing clones, duplicates, and bots from the market. Aside from that, enjoy resource sharing, loyalty, asset trading, and much more.",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          {Bcards.map((card, index) => (
            <div className="flip-card col-lg-4 col-md-6 col-sm-12" key={index}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={card.imageUrl} alt="Avatar" />
                  <h3>{card.title}</h3>
                </div>
                <div className="flip-card-back pt-4 px-2">
                  <h2>{card.title}</h2>
                  <p>{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Cards;
